import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { canActivate } from '@angular/fire/auth-guard';
import { NoEmailVerifiedComponent } from '@pages/no-email-verified/no-email-verified.component';
import { EmailNotVerifiedGuard, EmailVerifiedGuard } from '@pages/guards/emailVerified.guard';
import { AuthGuard } from './auth/guards/auth.guard';
import { Error404Component } from '@modules/errors/error404/error404.component';

export const routes: Routes = [
  // Rutas relacionadas con autenticación
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth/auth.module').then((m) => m.AuthModule),
  },
  // Ruta para usuarios sin correo verificado
  {
    path: 'email-verification',
    component: NoEmailVerifiedComponent,
    canActivate: [EmailNotVerifiedGuard],
  },
  // Rutas para manejo de errores
  {
    path: 'error/:ID',
    loadChildren: () =>
      import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
  },
  // Rutas principales protegidas
  {
    path: '',
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
    canActivate: [AuthGuard, EmailVerifiedGuard, canActivate],
  },
  // Redirección para rutas no encontradas
  {
    path: '**',
    component: Error404Component
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
