import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() { }

  // Guardar un objeto en el sessionStorage
  setObject(key: string, value: any): void {
    const jsonValue = JSON.stringify(value);
    sessionStorage.setItem(key, jsonValue);
  }

  // Obtener un objeto del sessionStorage
  getObject(key: string): any {
    const jsonValue = sessionStorage.getItem(key);
    if (jsonValue) {
      return JSON.parse(jsonValue);
    }
    return null;
  }

  // Eliminar un objeto del sessionStorage
  removeItem(key: string): void {
    sessionStorage.removeItem(key);
  }

  // Limpiar todo el sessionStorage
  clear(): void {
    sessionStorage.clear();
  }

}
