import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { catchError, filter, map, Observable, of, take } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';

export const EmailVerifiedGuard: CanActivateFn = (): Observable<boolean> => {
  const router = inject(Router);
  const authService = inject(AuthService);
  return authService.getAuthState().pipe(
    take(1),
    filter((user) => user !== null),
    map((user) => {
      if (user?.emailVerified) {
        return true;
      } else {
        router.navigate(['/email-verification']);
        return false;
      }
    }),
    catchError((error) => {
      console.error('EmailVerifiedGuard | Error detectado:', error);
      router.navigate(['/error']);
      return of(false);
    })
  );
};

export const EmailNotVerifiedGuard: CanActivateFn = (): Observable<boolean> => {
  const router = inject(Router);
  const authService = inject(AuthService);
  return authService.getAuthState().pipe(
    take(1),
    map((user) => {
      if(user){
        if (user?.emailVerified) {
          router.navigate(['/']);
          return false;
        } else {
          return true;
        }
      }else{
        router.navigate(['/auth/login']);
        return false;
      }
    }),
    catchError((error) => {
      console.error('EmailVerifiedGuard | Error detectado:', error);
      router.navigate(['/error']);
      return of(false);
    })
  );
};;
