import { Injectable } from '@angular/core';
import {
  addDoc,
  collection,
  CollectionReference,
  doc,
  DocumentData,
  DocumentReference,
  Firestore,
  WhereFilterOp,
  getDocs,
  deleteDoc,
  DocumentSnapshot,
  FirestoreError,
  FieldPath,
  getDoc,
  where,
  onSnapshot,
  query,
  QuerySnapshot,
  runTransaction,
  setDoc,
  Transaction,
  updateDoc,
  getFirestore,
  QueryConstraint,
} from '@angular/fire/firestore';
import {
  asyncScheduler,
  scheduled,
  BehaviorSubject,
  catchError,
  forkJoin,
  from,
  map,
  Observable,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import {
  ref,
  uploadBytes,
  getDownloadURL,
  Storage,
} from '@angular/fire/storage';
import { HashingCryptService } from './hashing-crypt.service';

@Injectable({
  providedIn: 'root',
})
export class FireBaseCoreService {

  errorMessagesSub: BehaviorSubject<string>;
  currentDocuUserDocument: BehaviorSubject<DocumentData>;
  private firestoreInstance: Firestore;

  constructor(
    private firestore: Firestore,
    private storage: Storage,
    private hashingCryptService: HashingCryptService
  ) {
    this.errorMessagesSub = new BehaviorSubject<string>('');
    this.firestoreInstance = getFirestore();
  }

  /**
 * Lee todos los documentos de la colección que se manda como parámetro.
 * 
 * @param collectionName - Nombre de la colección con la que se va a trabajar.
 * @returns Un Observable que emite un array de documentos de la colección actual.
 */
  public readDocuments(collectionName: string): Observable<{ uid: string; data: DocumentData }[]> {
    const collectionRef = collection(this.firestore, collectionName);
    const q = query(collectionRef);

    return new Observable<{ uid: string; data: DocumentData }[]>((observer) => {
      const unsubscribe = onSnapshot(q, (querySnapshot: QuerySnapshot<DocumentData>) => {
        const result = querySnapshot.docs.map(doc => ({
          uid: doc.id,
          data: doc.data()
        }));
        observer.next(result);
      }, (error) => {
        observer.error(error);
      });

      return () => unsubscribe();
    }).pipe(
      catchError(this.handleError)
    );
  }


  /**
 * Crea un nuevo documento en la colección especificada con los datos proporcionados.
 * 
 * Este método agrega un documento a la colección y devuelve la referencia del documento creado.
 * 
 * @param data - Los datos del documento que se va a crear.
 * @param collectionName - El nombre de la colección en la que se va a crear el documento.
 * @param uid - (Opcional) El ID del documento que se va a crear. Si no se proporciona, se generará uno automáticamente.
 * @returns Un Observable que emite la referencia del documento creado.
 * 
 * @throws Error - Lanza una excepción si ocurre un error durante la creación del documento.
 */
  public createDocument(data: any, collectionName: string, uid: string | null = null): Observable<{ uid: string; data: any }> {
    const collectionRef = collection(this.firestore, collectionName);
    if (uid) {
      const docRef = doc(collectionRef, uid);
      return from(setDoc(docRef, data)).pipe(
        map(() => {
          return { uid, data };
        }),
        catchError((error) => {
          console.error('Error al crear el documento:', error);
          return throwError(() => error);
        })
      );
    } else {
      return from(addDoc(collectionRef, data)).pipe(
        map((docRef) => {
          const id = docRef.id;
          return { uid: id, data };
        }),
        catchError((error) => {
          console.error('Error al crear el documento:', error);
          return throwError(() => error);
        })
      );
    }
  }

  /**
 * Crea o actualiza un documento en la colección especificada con los datos proporcionados.
 * 
 * Este método verifica si el documento con el ID proporcionado ya existe en la colección.
 * Si existe, lo actualiza con los nuevos datos; si no existe, lo crea.
 * 
 * @param data - Los datos del documento que se va a crear o actualizar.
 * @param collectionName - El nombre de la colección en la que se va a crear o actualizar el documento.
 * @param uid - El ID del documento que se va a crear o actualizar. Si no se proporciona, se generará uno automáticamente.
 * @returns Un Observable que emite la referencia del documento creado o actualizado.
 * 
 * @throws Error - Lanza una excepción si ocurre un error durante la creación o actualización del documento.
 */
  public createOrUpdateDocument(data: any, collectionName: string, uid: string): Observable<{ uid: string; data: any }> {
    const collectionRef = collection(this.firestore, collectionName);
    const docRef = doc(collectionRef, uid);

    return from(getDoc(docRef)).pipe(
      switchMap((docSnap) => {
        if (docSnap.exists()) {
          // El documento existe, actualízalo.
          return from(updateDoc(docRef, data)).pipe(
            map(() => {
              return { uid, data };
            })
          );
        } else {
          // El documento no existe, créalo.
          return from(setDoc(docRef, data)).pipe(
            map(() => {
              return { uid, data };
            })
          );
        }
      }),
      catchError((error) => {
        console.error('Error al crear o actualizar el documento:', error);
        return throwError(() => error);
      })
    );
  }

  /**
 * Actualiza un campo específico de un documento en la colección por su ID.
 * @param documentId El ID del documento que se va a actualizar.
 * @param fieldName El nombre del campo que se va a actualizar.
 * @param value El nuevo valor del campo.
 * @param collectionName El nombre de la colección donde se encuentra el documento.
 * @returns Observable que emite el documento actualizado.
 */
  public updateDocumentField(documentId: string, fieldName: string, value: any, collectionName: string): Observable<any> {
    const documentRef = doc(collection(this.firestore, collectionName), documentId);
    const updateData = { [fieldName]: value };

    return from(updateDoc(documentRef, updateData)).pipe(
      switchMap(() => {
        return from(getDoc(documentRef)).pipe(
          map((docSnapshot) => {
            if (docSnapshot.exists()) {
              const id = docSnapshot.id;
              const docData = docSnapshot.data();
              return { uid: id, ...docData };
            }
            return null;
          }),
          catchError((error) => {
            console.error('Error al obtener el documento después de la actualización:', error);
            throw error;
          })
        );
      }),
      catchError((error) => {
        console.error('Error al actualizar el campo del documento:', error);
        throw error; // Propaga el error
      })
    );
  }



  /**
   * Obtiene un documento de una colección por su ID. Si no lo encuentra, lo crea con los datos proporcionados.
   * 
   * @param documentId - El ID del documento que se va a obtener.
   * @param collectionName - El nombre de la colección en la que se encuentra el documento.
   * @param data - Los datos con los que se creará el documento si no existe.
   * @returns Un Observable que emite el documento encontrado o creado.
   */
  public getOrCreateDocument(documentId: string, collectionName: string, data: any): Observable<any> {
    const docRef = doc(this.firestore, collectionName, documentId);

    return from(getDoc(docRef)).pipe(
      map((docSnapshot) => {
        if (docSnapshot.exists()) {
          // Documento encontrado, retornarlo
          const id = docSnapshot.id;
          const documentData = docSnapshot.data();
          return { uid: id, ...documentData };
        } else {
          // Documento no encontrado, crear uno nuevo con los datos proporcionados
          return from(setDoc(docRef, data, { merge: true })).pipe(
            tap(console.log),
            map(() => ({ uid: documentId, ...data })), // Retorna el documento creado
            catchError(this.handleError) // Manejo de errores al crear el documento
          );
        }
      }),
      catchError(this.handleError) // Manejo de errores al obtener el documento
    );
  }

  public createDocumentCollectionReference(data: any, collectionRef: CollectionReference<DocumentData>, uid?: string): Observable<{ uid: string; data: any }> {
    if (uid) {
      const docRef = doc(collectionRef, uid);
      return from(setDoc(docRef, data)).pipe(
        map(() => {
          return { uid, data };
        }),
        catchError((error) => {
          console.error('Error al crear el documento:', error);
          return throwError(() => error);
        })
      );
    } else {
      return from(addDoc(collectionRef, data)).pipe(
        map((docRef) => {
          const id = docRef.id;
          return { uid: id, data };
        }),
        catchError((error) => {
          console.error('Error al crear el documento:', error);
          return throwError(() => error);
        })
      );
    }
  }


  /**
     * Obtiene un documento de una colección por su ID.
     * 
     * @param documentId - El ID del documento que se va a obtener.
     * @param collectionName - El nombre de la colección en la que se encuentra el documento.
     * @returns Un Observable que emite el documento encontrado o un error si no se encuentra.
     */
  public getDocumentById(documentId: string, collectionName: string): Observable<any> {
    const docRef = doc(collection(this.firestore, collectionName), documentId);

    return from(getDoc(docRef)).pipe(
      map((docSnapshot) => {
        if (docSnapshot.exists()) {
          const id = docSnapshot.id;
          const data = docSnapshot.data();
          return { uid: id, ...data };
        } else {
          return null
        }
      }),
      catchError(this.handleError)
    );
  }

  /**
 * Obtiene documentos a partir de un arreglo de DocumentReference.
 * 
 * Este método consulta los documentos referenciados y devuelve un Observable que emite un array de documentos.
 * 
 * @param references - Un arreglo de DocumentReference que representa los documentos a recuperar.
 * @returns Un Observable que emite un array de documentos obtenidos.
 * 
 * @throws Error - Lanza una excepción si ocurre un error durante la operación.
 */
  // Método para obtener documentos a partir de un arreglo de DocumentReference
  public getDocumentsByReferences<T>(references: DocumentReference<DocumentData>[]): Observable<T[]> {
    const documentObservables = references.map(ref => from(getDoc(ref)).pipe(
      map((docSnapshot) => {
        return docSnapshot.exists() ? ({ uid: docSnapshot.id, ...docSnapshot.data() } as T) : null;
      }),
      catchError(error => {
        console.error('Error al obtener el documento referenciado:', error);
        return of(null); // Devolver null en caso de error
      })
    ));

    return forkJoin(documentObservables).pipe(
      map(docs => docs.filter((doc): doc is T => doc !== null)), // Filtrar los documentos que no son nulos
      catchError(this.handleError)
    );
  }

  /**
 * Actualiza un documento específico de la colección por su ID con los datos proporcionados.
 * @param documentId El ID del documento que se va a actualizar.
 * @param data Los datos actualizados del documento.
 * @param collectionName El nombre de la colección donde se encuentra el documento.
 * @returns Observable que emite el documento actualizado.
 */
  public updateDocument(documentId: string, data: any, collectionName: string): Observable<any> {
    return from(updateDoc(doc(collection(this.firestore, collectionName), documentId), data)).pipe(
      switchMap(() => {
        return from(getDoc(doc(collection(this.firestore, collectionName), documentId))).pipe(
          map((docSnapshot) => {
            if (docSnapshot.exists()) {
              const id = docSnapshot.id;
              const docData = docSnapshot.data();
              return { uid: id, ...docData };
            }
            return null;
          }),
          catchError((error) => {
            console.error(
              'Error al obtener el documento después de la actualización:',
              error
            );
            throw error;
          })
        );
      }),
      catchError((error) => {
        console.error('Error al actualizar el documento:', error);
        throw error; // Propaga el error
      })
    );
  }

  /* 
    TODO Faltan por agregar muchos mas metodos
  */

  public performTransaction(transactionFunction: (transaction: Transaction) => Promise<void>): Observable<void> {
    return from(runTransaction(this.firestore, transactionFunction)).pipe(
      catchError((error) => {
        console.error('Error en la transacción:', error);
        return throwError(() => error);
      })
    );
  }


  public getCollectionRef(collectionName: string): CollectionReference<DocumentData> {
    return collection(this.firestore, collectionName);
  }

  public getSubCollectionRef(collectionName: string, docId: string, subCollectionName: string): CollectionReference<DocumentData> {
    return collection(this.firestore, collectionName, docId, subCollectionName);
  }

  /**
   * Maneja errores en los métodos que devuelven un Observable.
   * 
   * Este método se encarga de registrar el error en la consola y lanzar una nueva excepción con un mensaje genérico.
   * Es útil para manejar errores en operaciones asíncronas, como las peticiones a Firebase, y proporcionar un mensaje de error claro al usuario.
   * 
   * @param error - El error que ocurrió, puede ser cualquier tipo de error capturado en la operación asíncrona.
   * @returns Un Observable que nunca emite valores porque lanza una excepción en lugar de emitir datos.
   * 
   * @throws Error - Lanza una nueva excepción con un mensaje genérico para que el flujo de datos del observable sea interrumpido.
   * 
   * @example
   * ```typescript
   * this.firestoreService.getDocuments().pipe(
   *   catchError(this.handleError)
   * ).subscribe(
   *   data => console.log(data),
   *   error => console.error(error) // Aquí se captura la excepción lanzada por handleError
   * );
   * ```
   */
  private handleError(error: any): Observable<never> {
    console.error('Error:', error);
    throw new Error('Algo salió mal; por favor, intenta otra vez después.');
  }

  async deleteDocumentInCollection(
    collectionID: string,
    documentId: string,
  ) {
    const documentReference = doc(this.firestore, collectionID, documentId);
    return deleteDoc(documentReference);
  }

  async checkIfDocumentExist(collectionID: string, documentId: string) {
    const documentReference = doc(this.firestore, collectionID, documentId);
    const docSnap = await getDoc(documentReference);
    if (docSnap.exists()) {
      return true;
    } else {
      return false;
    }
  }

  getRealtimeSnapShot(docRef: DocumentReference<unknown, DocumentData>, obsFunc: { next?: ((snapshot: DocumentSnapshot<unknown, DocumentData>) => void) | undefined; error?: ((error: FirestoreError) => void) | undefined; complete?: (() => void) | undefined; }) {
    return onSnapshot(docRef, obsFunc)
  }

  async getQueryDocument(collectionID: string, fieldPath: any | FieldPath, operatorToQuery: WhereFilterOp, valueToSearch: any | Array<any>) {
    const documentReference = collection(this.firestore, collectionID);
    const q = query(documentReference, where(fieldPath, operatorToQuery, valueToSearch));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs;
  }

  async getQueryDocumentWithMultipleWhere(
    collectionID: string,
    whereParams: WhereParams[],
  ) {
    const queryConstraintList: QueryConstraint[] = [];
    whereParams.forEach((whereParam) => {
      queryConstraintList.push(where(whereParam.fieldPath, whereParam.operatorToQuery, whereParam.valueToSearch));
    })
    const documentReference = collection(this.firestore, collectionID);
    const theQuery = query(documentReference, ...queryConstraintList);
    const querySnapshot = await getDocs(theQuery);
    return querySnapshot.docs;

  }

  updateDocumentInCollection(collectionID: string, documentId: string, documentData: DocumentData) {
    const documentReference = doc(this.firestore, collectionID, documentId);
    return updateDoc(documentReference, documentData);
  }

  async insertDocumentToCollection(
    collectionID: string,
    documentId: string,
    documentData: any,
    mergeOption: boolean = true
  ) {
    const documentReference = doc(this.firestore, collectionID, documentId);
    setDoc(documentReference, documentData, { merge: mergeOption })
      .catch(
        (error) => {
          throw new Error('Error al insertar documento a la colección: ' + error);
        }
      );
    return documentData;
  }

  insertDocumentByReference(docReference: DocumentReference<DocumentData, DocumentData>, documentData: any): Observable<any> {
    return scheduled(from(setDoc(docReference, documentData, { merge: false })), asyncScheduler)
    /* setDoc(docReference, documentData, { merge: false })
    .then(()=>{
    })
    .catch(
      (error) => {
        throw new Error('Error al insertar documento a la colección: ' + error);
      }
    ); */
  }

  /* async sendEmailFromDocu(){ 
    this.insertDocumentToCollection('emailSent', '', {email: 'Sended'}, false);
    const calledEmailSended = await callableFuncEmailSender().then((result)=>{
      console.log(':::::::: response: ' + JSON.stringify(result))
      return result;
    })
    .catch((error)=>{console.error(':::::KnowItError: sendEmailFromDocu ', error)});
    return calledEmailSended;
  }; */

  getDocData(collection: string, docId: string) {
    return getDoc(doc(this.firestore, collection, docId));
  }

  /**
   * Obtiene el DocumentReference nuevo. Este método debe usarse antes de guardar en firestore.
   * 
   * @param collectionName - El nombre de la colección en la que se va a guardar el documento.
   * @returns La referencia del nuevo documento en la colección.
   * @author Alan Sierra
   */
  public getDocReference(collectionName: string, documentId?: string | null): DocumentReference<DocumentData, DocumentData> {
    if (documentId) {
      return doc(collection(this.firestore, collectionName), documentId);
    } else {
      return doc(collection(this.firestore, collectionName));
    }
  }

  /**
   * Obtiene el CollectionReference de una Colección.
   * 
   * @param collectionName - El nombre de la colección en la que se va a guardar el documento.
   * @returns La referencia a la colección.
   * @author Alan Sierra
   */
  public getCollectionReference(collectionName: string): CollectionReference<DocumentData, DocumentData> {
    return collection(this.firestore, collectionName);
  }

  getFileReferenceStorage(gCSURI: string) {
    return getDownloadURL(ref(this.storage, gCSURI));
  }

  validateIfDocumentExist(documentName: string) {
    const docRef = doc(this.firestore, documentName);
    return docRef.id ? docRef.id : null;
  }

  uploadFile(fileToUpload: File) {
    const storageRef = ref(this.storage, 'COMPANYIDHEREWITHVAR/' + fileToUpload.name);
    return uploadBytes(storageRef, fileToUpload);
  }

  public async uploadFileBlob(fileToUpload: Blob) {
    const storageReference = ref(this.storage, 'some-child');
    const uploadResultSnapshot = await uploadBytes(
      storageReference,
      fileToUpload
    );
    return (
      uploadResultSnapshot.ref.root + '/' + uploadResultSnapshot.ref.fullPath
    );
  }

  /**
  * Lee todos los documentos de la colección que se manda como parametro
  * @param collectionName Nombre de la collection con la que se va a trabajar
  * @returns Observable que emite un array de documentos de la colección actual.
  */
  public readDocumentsWithCollection<T>(collectionName: string): Observable<T[]> {
    const collectionRefUpdate = collection(this.firestoreInstance, collectionName);
    const q = query(collectionRefUpdate);
    return new Observable<T[]>((observer) => {
      const unsubscribe = onSnapshot(q, (querySnapshot: QuerySnapshot<DocumentData>) => {
        const result: T[] = [];
        querySnapshot.forEach((doc) => {
          const id = doc.id;
          const data = doc.data() as T;
          result.push({ uid: id, ...data });
        });
        observer.next(result);
      }, (error) => {
        observer.error(error);
      });

      return () => unsubscribe();
    }).pipe(
      catchError(this.handleError)
    );
  }

  public getDocumentByIdWithCollection<T>(documentId: string, collectionName: string): Observable<T> {
    const docRef = doc(collection(this.firestoreInstance, collectionName), documentId);

    return from(getDoc(docRef)).pipe(
      map((docSnapshot) => {
        const id = docSnapshot.id;
        const data = docSnapshot.data() as T;
        return { uid: id, ...data };
      }),
      catchError(this.handleError)
    );
  }

  /**
   * Obtiene documentos de una colección donde la propiedad activeData.creatorUid coincide con el UID proporcionado.
   * @param collectionName Nombre de la colección a consultar.
   * @param creatorUid UID del creador a buscar.
   * @returns Observable que emite un array de documentos que cumplen con la condición.
   */
  public getDocumentsByCreatorUid<T>(collectionName: string, creatorUid: string): Observable<T[]> {
    const collectionRef = collection(this.firestoreInstance, collectionName);
    const q = query(collectionRef, where('activeData.creatorUid', '==', creatorUid));

    return from(getDocs(q)).pipe(
      map((querySnapshot: QuerySnapshot<DocumentData>) => {
        const result: T[] = [];
        querySnapshot.forEach((doc) => {
          const id = doc.id;
          const data = doc.data() as T;
          result.push({ uid: id, ...data });
        });
        return result;
      }),
      catchError(this.handleError)
    );
  }

  /**
 * Actualiza un documento específico de la colección por su ID con los datos proporcionados.
 * @param documentId El ID del documento que se va a actualizar.
 * @param data Los datos actualizados del documento.
 * @param collectionName El nombre de la colección donde se encuentra el documento.
 * @returns Observable que emite el documento actualizado.
 */
  public updateDocumentWithCollection<T>(documentId: string, data: any, collectionName: string): Observable<T | null> {
    return from(updateDoc(doc(collection(this.firestoreInstance, collectionName), documentId), data)).pipe(
      switchMap(() => {
        return from(getDoc(doc(collection(this.firestoreInstance, collectionName), documentId))).pipe(
          map((docSnapshot) => {
            if (docSnapshot.exists()) {
              const id = docSnapshot.id;
              const docData = docSnapshot.data() as T;
              return { uid: id, ...docData };
            }
            return null;
          }),
          catchError((error) => {
            console.error(
              'Error al obtener el documento después de la actualización:',
              error
            );
            throw error;
          })
        );
      }),
      catchError((error) => {
        console.error('Error al actualizar el documento:', error);
        throw error; // Propaga el error
      })
    );
  }

  // Método para generar un UID único
  generateUID(): string {
    const newDocRef = doc(collection(this.firestoreInstance, this.hashingCryptService.hash_HmacSHA256('GeneratedData', 'GeneratedKey')));
    return newDocRef.id;
  }

  /**
   * Elimina un documento de la colección especificada.
   * @param documentId El ID del documento que se desea eliminar.
   * @returns Un observable que emite un mensaje de éxito cuando el documento se elimina correctamente,
   * o un mensaje de error si no se encuentra el documento o si ocurre un error durante el proceso de eliminación.
   */
  deleteDocument(documentId: string, collectionName: string): Observable<string> {
    const collectionRef = collection(this.firestoreInstance, collectionName);
    const docRef = doc(collectionRef, documentId);
    return from(getDoc(docRef)).pipe(
      switchMap((docSnapshot) => {
        if (docSnapshot.exists()) {
          return from(deleteDoc(docSnapshot.ref)).pipe(
            switchMap(() => {
              return of(`Se ha eliminado el documento con ID: ${documentId}`);
            })
          );
        } else {
          return throwError(
            () =>
              new Error(
                `No se encontró ningún documento con el ID: ${documentId}`
              )
          );
        }
      }),
      catchError((error) => {
        console.error('Error al intentar eliminar el documento:', error);
        throw new Error('Error al intentar eliminar el documento');
      })
    );
  }
  /**
 * Elimina un documento de la colección especificada.
 * @param documentId El ID del documento que se desea eliminar.
 * @param collectionName El nombre de la colección donde se encuentra el documento.
 * @returns Un observable que emite un mensaje de éxito cuando el documento se elimina correctamente,
 * o un mensaje de error si no se encuentra el documento o si ocurre un error durante el proceso de eliminación.
 */
  public deleteDocumentWithCollection(documentId: string, collectionName: string): Observable<string> {
    const docRef = doc(collection(this.firestoreInstance, collectionName), documentId);

    return from(getDoc(docRef)).pipe(
      switchMap((docSnapshot) => {
        if (docSnapshot.exists()) {
          return from(deleteDoc(docSnapshot.ref)).pipe(
            switchMap(() => {
              return of(`Se ha eliminado el documento con ID: ${documentId}`);
            })
          );
        } else {
          return throwError(
            () =>
              new Error(
                `No se encontró ningún documento con el ID: ${documentId}`
              )
          );
        }
      }),
      catchError((error) => {
        console.error('Error al intentar eliminar el documento:', error);
        throw new Error('Error al intentar eliminar el documento');
      })
    );
  }

  /**
 * Elimina todos los documentos de la colección actual.
 * @param collectionName El nombre de la colección que se desea vaciar.
 * @returns Un observable que emite un valor booleano indicando el éxito de la operación.
 * En caso de que la colección esté vacía o no exista, el observable emitirá un error.
 */
  public deleteAllDocumentsWithCollection(collectionName: string): Observable<boolean> {
    // Obtener una instantánea de la colección
    return from(getDocs(collection(this.firestoreInstance, collectionName))).pipe(
      switchMap((querySnapshot) => {
        // Verificar si la colección tiene algún documento
        if (querySnapshot.size === 0) {
          throw new Error(
            'La colección está vacía, no hay documentos para eliminar.'
          );
        } else {
          // Obtener la referencia de la colección
          const collectionRef = collection(this.firestoreInstance, collectionName);
          return from(getDocs(collectionRef)).pipe(
            map((querySnapshot) => querySnapshot.docs.map((doc) => doc.ref)),
            tap((docRefs) => {
              // Eliminar cada documento de la colección
              docRefs.forEach((docRef) => {
                deleteDoc(docRef);
              });
            }),
            map(() => true),
            catchError((error) => {
              console.error(
                'Error al intentar eliminar todos los documentos:',
                error
              );
              return throwError(
                () => 'Error al intentar eliminar todos los documentos'
              );
            })
          );
        }
      })
    );
  }

}

export interface WhereParams {
  fieldPath: any | FieldPath;
  operatorToQuery: WhereFilterOp;
  valueToSearch: any | Array<any>;
}