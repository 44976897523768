import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private renderer: Renderer2;

  private theme: BehaviorSubject<string | null> = new BehaviorSubject<string | null>('')

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  initializeTheme() {
    const storedTheme = localStorage.getItem('app-theme');
    if (storedTheme) {
      this.theme.next(storedTheme);
      this.loadTheme(storedTheme);
    } else {
      this.detectSystemTheme();
    }
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', this.detectSystemTheme.bind(this));
  }

  detectSystemTheme(): string {
    const isDarkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const theme = isDarkTheme ? 'arya-blue' : 'lara-light-blue';
    this.theme.next(theme)
    this.loadTheme(theme);
    return theme;
  }

  loadTheme(theme: string) {
    const existingLinkElement = document.getElementById('app-theme') as HTMLLinkElement;
    if (existingLinkElement) {
      existingLinkElement.href = `assets/themes/${theme}/theme.css`;
    } else {
      const linkElement = this.renderer.createElement('link');
      linkElement.id = 'app-theme';
      linkElement.rel = 'stylesheet';
      linkElement.href = `assets/themes/${theme}/theme.css`;
      this.renderer.appendChild(document.head, linkElement);
    }
  }

  storeTheme(theme: string) {
    localStorage.setItem('app-theme', theme);
  }

  getStoredTheme(): string | null {
    return localStorage.getItem('app-theme');
  }

  getTheme(): Observable<string | null> {
    return this.theme.asObservable()
  }
}
