import { Injectable } from '@angular/core';
import { Auth, createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword, signOut, updatePassword } from '@angular/fire/auth';
import { BehaviorSubject, Observable, catchError, filter, from, map, of, switchMap, tap, throwError } from 'rxjs';
import { UserAccess, UserFirstConfiguration, UserLogin } from '../interfaces/user.interface';
import { jwtDecode } from 'jwt-decode'; // Importación correcta
import { UserSessionService } from '@pages/user/services/user-session.service';
import { GoogleAuthProvider, OAuthProvider, signInWithPopup, User } from 'firebase/auth';
import { Firestore, getDocs, query, where } from '@angular/fire/firestore';
import { collection } from 'firebase/firestore';
import { FireStoreCollectionsService } from 'src/app/shared/services/fire-store-collections.service';
import { FireBaseCoreService } from 'src/app/core/services/firebase-core-service-firestore.service';
import { DocuUser } from 'src/app/core/interfaces/user-model.interface';

@Injectable({ providedIn: 'root' })
export class AuthService {

  private googleProvider = new GoogleAuthProvider();
  private microsoftProvider = new OAuthProvider('microsoft.com');
  public userAccessSubject = new BehaviorSubject<UserAccess | null>(null);
  public docuUser$: Observable<DocuUser | null>;
  private docuUserSubject: BehaviorSubject<DocuUser | null> = new BehaviorSubject<DocuUser | null>(null);

  constructor(
    private fireBaseCoreService: FireBaseCoreService,
    private auth: Auth,
    private userSessionService: UserSessionService,
    private firestore: Firestore,
    private fireStoreCollectionsService: FireStoreCollectionsService,
  ) {
    this.docuUser$ = this.userSessionService.getDocuUser();
  }

  public reloadUser(){
    this.auth.currentUser?.reload();
  }

  public getAuthState(): Observable<User | null> {
    return new Observable<User | null>((observer) => {
      return this.auth.onAuthStateChanged(
        (user) => observer.next(user),
        (error) => observer.error(error),
        () => observer.complete()
      );
    });
  }

  public getAuthStateReady(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
        this.auth.authStateReady().then(() => {
            observer.next(true);
            observer.complete();
        }).catch((error) => {
            console.error('Error en authStateReady:', error);
            observer.next(false);
            observer.complete();
        });
    });
}

  loginWithEmailAndPassword(user: UserLogin): Observable<UserAccess> {
    return from(signInWithEmailAndPassword(this.auth, user.email, user.password)).pipe(
      filter((userCredential)=> userCredential !== null),
      map((userCredential: any) => {
        const userData = {
          uid: userCredential.user.uid,
          accessToken: userCredential.user.accessToken,
          email: userCredential.user.email,
          emailVerified: userCredential.user.emailVerified
        }
        this.loadAndSetDocuUser(userData);
        this.userAccess = userData;
        return userData;
      }),
      catchError((error: any) => {
        console.error('Error en el login:', error);
        throw error; // Propaga el error para manejarlo en un nivel superior si es necesario
      })
    );
  }

  logInWithGoogle(): Observable<UserAccess> {
    return from(signInWithPopup(this.auth, this.googleProvider)).pipe(
      filter((userCredential)=> userCredential !== null),
      map((userCredential: any) => {
        const userData: UserAccess = {
          uid: userCredential.user.uid,
          accessToken: userCredential.user.accessToken,
          email: userCredential.user.email,
          displayName: userCredential.user.displayName,
        };
        this.loadAndSetDocuUser(userData);
        this.userAccess = userData;
        return userData
      }),
      catchError(error => {
        console.error('Error during Google sign-in:', error);
        throw error; // Re-throw the error to be handled by the component
      })
    );
  }

  loginWithMicrosoft(): Observable<UserAccess> {
    return from(signInWithPopup(this.auth, this.microsoftProvider)).pipe(
      filter((userCredential)=> userCredential !== null),
      map((userCredential: any) => {
        const userData: UserAccess = {
          uid: userCredential.user.uid,
          accessToken: userCredential.user.accessToken,
          email: userCredential.user.email, //TODO: Procesar email de microsoft...
        };
        this.loadAndSetDocuUser(userData);
        this.userAccess = userData;
        return userData
      }),
      catchError(error => {
        console.error('Error during Google sign-in:', error);
        throw error; // Re-throw the error to be handled by the component
      })
    );
  }

  createUserWithEmailAndPassword(email: string, password: string, docuUser: DocuUser): Observable<any> {
    const user = this.auth.currentUser;
    console.log(user)
    return from(createUserWithEmailAndPassword(this.auth, email, password)).pipe(
      map((userCredential: any) => {
        console.log(userCredential)
        const userData: UserAccess = {
          uid: userCredential.user.uid,
          accessToken: userCredential.user.accessToken
        };
        this.loadAndSetDocuUser(userData, docuUser);
        this.userAccess = userData;
        return userData;
      }),
      catchError((error: any) => {
        console.error('Error al crear usuario:', error);
        throw error;
      })
    );
  }

  sendEmailVerification(): void {
    if (this.auth.currentUser) {
      sendEmailVerification(this.auth.currentUser)
        .then(() => {
          console.log('Email')
        });

    }
  }

  changePassword(currentPassword: string, newPassword: string): Observable<void> {
    const user = this.auth.currentUser;
    if (!user) {
      return throwError(() => new Error('No hay usuario autenticado.'));
    }

    // Primero, necesitamos reautenticar al usuario
    return from(signInWithEmailAndPassword(this.auth, user.email ?? '', currentPassword)).pipe(
      switchMap(() => {
        // Ahora que la reautenticación es exitosa, actualizamos la contraseña
        return from(updatePassword(user, newPassword));
      }),
      tap(() => {
        console.log('Contraseña actualizada exitosamente.');
        this.logout().subscribe()
      }),
      catchError((error: any) => {
        console.error('Error al actualizar la contraseña:', error);
        return throwError(() => new Error('Error al actualizar la contraseña.'));
      })
    );
  }

  logout(): Observable<void> {
    return from(signOut(this.auth)).pipe(
      tap(() => {
        //console.log('Usuario desconectado exitosamente.');
        // Limpiar cualquier dato de sesión si es necesario
        localStorage.clear();
        sessionStorage.clear();
        this.userSessionService.setDocuUser(null)
      }),
      catchError((error: any) => {
        //console.error('Error al cerrar sesión:', error);
        return throwError(() => new Error('Error al cerrar sesión.'));
      })
    );
  }

  isTokenExpired(token: string): boolean {
    try {
      const decoded: any = jwtDecode(token);
      const exp = decoded.exp;
      const now = Math.floor(new Date().getTime() / 1000);
      return now >= exp;
    } catch (error) {
      //console.error('Error al decodificar el token:', error);
      return true;
    }
  }

  checkUsernameExists(username: string, currentUserId: string): Observable<boolean> {
    const publicUsersRef = collection(this.firestore, this.fireStoreCollectionsService.worldUsers);
    const q = query(
      publicUsersRef,
      where('username', '==', username),
      where('uid', '!=', currentUserId) // Ignora el documento del usuario actual
    );

    return from(getDocs(q)).pipe(
      switchMap(snapshot => {
        return of(!snapshot.empty); // Retorna true si el username existe, false si no existe
      })
    );
  }

  public updateActiveStatus(userDoc: DocuUser): Observable<any> {
    return this.fireBaseCoreService.updateDocumentField(userDoc.uid, 'active', true, this.fireStoreCollectionsService.usersCollection).pipe(//Se modifica al usuario
      tap(console.log),
      switchMap(() => {
        return this.fireBaseCoreService.updateDocumentField(userDoc.uid, 'active', true, this.fireStoreCollectionsService.worldUsers).pipe(
          tap(console.log),
          catchError(this.handleError)
        )
      }),
      catchError(this.handleError)
    )
  }

  private loadAndSetDocuUser(userAccess: UserAccess, docuUser: DocuUser | null = null){
    this.fireBaseCoreService.getDocumentById(userAccess.uid, this.fireStoreCollectionsService.usersCollection).pipe(
      tap((docuUserData) => {
          console.log('docuUserDatadocuUserDatadocuUserData', docuUserData)
          if (docuUserData) {
              if (!docuUserData.active) {//El usuaio esta inactivo
                return this.updateActiveStatus(docuUserData)
              }
              this.userSessionService.setDocuUser(docuUserData);
          } else {
              this.createDocuUser(userAccess, docuUser).pipe(
                tap(
                  (thisDocuUser) => {
                    if(thisDocuUser){
                      this.sendEmailVerification();
                      this.userSessionService.setDocuUser(thisDocuUser);
                    }
                  }
                )
              ).subscribe();
          }
      }),
      catchError((error) => {
          console.error('Error al cargar los datos del usuario:', error);
          return of(null);
      })
  ).subscribe({
      error: (err) => console.error('Error en la suscripción de usuario:', err)
  });
  }

  public createDocuUser(userAccess: UserAccess, docuUser: DocuUser | null = null): Observable<DocuUser> {
    const newDocuUser: UserFirstConfiguration = {
      name: userAccess.displayName ?? '',
      email: userAccess.email ?? '',
      notificationPreferences: {
        sendNewDocuWorksFeatures: true,
        sendPromotionsAndDiscounts: true,
      },
      configured: false,
      active: true,
      ...docuUser,
    }
    let docuUserToReturn: DocuUser | null = null;
    return this.fireBaseCoreService.createDocument(newDocuUser, this.fireStoreCollectionsService.usersCollection, userAccess.uid).pipe(
      switchMap((newDocuUser) => {
        docuUserToReturn = newDocuUser.data as DocuUser;
        return of(docuUserToReturn);
      }),
      catchError(this.handleError)
    );
  }

  public createDocuUserDeprectado(userAccess: UserAccess, displayName?: string): Observable<any> {
    const newUser: UserFirstConfiguration = {
      name: displayName ?? '',
      email: userAccess.email ?? '',
      notificationPreferences: {
        sendNewDocuWorksFeatures: true,
        sendPromotionsAndDiscounts: true,
      },
      configured: false,
      active: true
    }
    return this.fireBaseCoreService.createDocument(newUser, this.fireStoreCollectionsService.usersCollection, userAccess.uid).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: any): Observable<never> {
    console.error('Error:', error);
    throw new Error('Algo salió mal; por favor, intenta otra vez después.');
  }

  private set userAccess(userAccess: UserAccess) {
    this.userAccessSubject.next(userAccess);
    this.userAccessSubject.complete();
  }

  public get userAccess(): Observable<UserAccess | null> {
    return this.userAccessSubject.asObservable();
  }

  private set docuUser(user: DocuUser | null) { this.docuUserSubject.next(user) }
  public get docuUser(): Observable<DocuUser | null> { return this.docuUserSubject.asObservable() }

}