import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { BehaviorSubject, catchError, filter, Observable, of, switchMap, tap } from 'rxjs';
import { DocuUser } from 'src/app/core/interfaces/user-model.interface';
import { FireBaseCoreService } from 'src/app/core/services/firebase-core-service-firestore.service';
import { FireStoreCollectionsService } from 'src/app/shared/services/fire-store-collections.service';
import { ImageUploaderService } from 'src/app/shared/services/image-uploader.service';

@Injectable({ providedIn: 'root' })
export class UserSessionService {

  private userSubject: BehaviorSubject<DocuUser | null> = new BehaviorSubject<DocuUser | null>(null);

  constructor(
    private fireBaseCoreService: FireBaseCoreService,
    private fireStoreCollectionsService: FireStoreCollectionsService,
    private auth: Auth,
    private imageUploaderService: ImageUploaderService,
  ) {
    this.loadUserSession();
  }

  private loadUserSession(): void {
    this.auth.authStateReady().then(() => {
      const firebaseUser = this.auth.currentUser;
      if (firebaseUser) {
        this.fireBaseCoreService.getDocumentById(firebaseUser.uid, this.fireStoreCollectionsService.usersCollection).pipe(
          filter(((docuUserData) => docuUserData !== null)),
          tap((docuUserData) => {
            this.setDocuUser(docuUserData);
            if (docuUserData.gcImageUrl) {
              this.imageUploaderService.getUrlFile(docuUserData.gcImageUrl).pipe(
                switchMap(url => {
                  const updatedUser = { ...docuUserData, imageUrl: url };
                  this.setDocuUser(updatedUser);
                  return of(updatedUser);
                })
              );
            }
          }),
          catchError((error) => {
            console.error('Error al cargar los datos del usuario:', error);
            return of(null);
          })
        ).subscribe({
          error: (err) => console.error('Error en la suscripción de usuario:', err)
        });
      }
    }).catch((error) => {
      console.error('Error en authStateReady:', error);
    });

  }

  public getUid(): string | null { return this.userSubject.value?.uid ?? null; }
  public getDocuUser(): Observable<DocuUser | null> { return this.userSubject.asObservable(); }
  public setDocuUser(user: DocuUser | null) { this.userSubject.next(user); }

}