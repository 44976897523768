<div class="w-screen h-screen flex justify-content-center align-items-center">
    <img class="w-200px" [src]="'./assets/themes/' + (theme$ | async) + '/images/docu_pages.png'" alt="Logo" />
    <p-card [style]="{ width: '360px' }">
        <div class="p-card-title text-center">Verificación de Email</div>
        <p class="m-0 mb-4">
            Para continuar ve a la bandeja de entrada de tu correo electronico y verifica tu cuenta.
        </p>
        <p-button class="mr-2" [disabled]="isDisabledReSendEmail" label=" {{ isDisabledReSendEmail ? 'Wait ' + (timerToReSendEmail | async) + ' seconds ' : 'Resend Email'}}" (onClick)="reSendEmailVerification()">
            <i class="pi pi-envelope"></i>
        </p-button>
        <p-button label="Reload Page" (onClick)="reloadPage()">
            <i class="pi pi-check-circle"></i>
        </p-button>
    </p-card>
</div>