import { Injectable } from '@angular/core';
import CryptoES from 'crypto-es';

@Injectable({ providedIn: 'root' })
export class HashingCryptService {

    private cipherParams = {
        mode: CryptoES.mode.CBC,
        padding: CryptoES.pad.Pkcs7
      }
    
    constructor() { }

    /**
     * @function hash_HmacSHA256
     * @description Genera un hash HMAC utilizando el algoritmo MD5.
     *              Esta función utiliza la biblioteca `CryptoES` para calcular el HMAC.
     *
     * @param {string} s - La cadena de texto (mensaje) que se desea cifrar.
     * @param {string} k - La clave secreta utilizada para generar el HMAC.
     * @returns {WordArray} - El resultado del HMAC en forma de un objeto `WordArray`.
     *
     * @example
     * const message = "Hello, World!";
     * const secretKey = "mySecretKey";
     * const hash = hash_HmacSHA256(message, secretKey);
     * console.log(hash.toString()); // Ejemplo de salida: "fc3ff98e8c6a0d3087d515c0473f8677"
     *
     * @throws {Error} - Lanza un error si la biblioteca `CryptoES` no está disponible.
     */
    public hash_HmacSHA256(s: string, k: string): string {
        return CryptoES.HmacSHA256(s, k).toString();
    }

    private encAES(s: string, k: string): string {
        const kk = this.hash_HmacSHA256(k, k);
        return CryptoES.AES.encrypt(s, kk, this.cipherParams).toString();
    }

    private decAES(s: string, k: string): string {
        const kk = this.hash_HmacSHA256(k, k);
        return CryptoES.AES.decrypt(s, kk, this.cipherParams).toString(CryptoES.enc.Utf8);
    }

    private toUrlSafeBase64(base64: string): string {
        return base64.replace(/[+/=]/g, (match) => {
        switch (match) {
        case '+': return '.';
        case '/': return '~';
        case '=': return '';
        default: return match;
        }
        });
    }

    private fromUrlSafeBase64(urlSafeBase64: string): string {
        return urlSafeBase64.replace(/[.~]/g, (match) => {
        switch (match) {
        case '.': return '+';
        case '~': return '/';
        default: return match;
        }
        }).padEnd(Math.ceil(urlSafeBase64.length / 4) * 4, '=');
    }

    public getURLHash(value: string, k: string){
        return this.toUrlSafeBase64(this.encAES(value, k));
    }

    public decodeURLHash(value: string, k: string){
        return this.decAES(this.fromUrlSafeBase64(value), k);
    }

}