import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class FireStoreCollectionsService {
    constructor() { }

    get usersCollection(): string {
        return environment.docuworksConfig.tables.users;
    }

    get hubCollection(): string {
        return environment.docuworksConfig.tables.docuHubs;
    }

    get workspaceCollection(): string {
        return environment.docuworksConfig.tables.workSpaces;
    }

    get collectionCollection(): string {
        return environment.docuworksConfig.tables.collections;
    }

    get docuDocuTypeCollection(): string {
        return environment.docuworksConfig.tables.docutypes;
    }

    get activeDocuCollection(): string {
        return environment.docuworksConfig.tables.activeDocus;
    }

    get worldUsers(): string {
        return environment.docuworksConfig.tables.worldUsers
    }

    get profilesCollection(): string {
        return environment.docuworksConfig.tables.profiles
    }

}