import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, concat, filter, Observable, tap } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ThemeService } from 'src/app/widgets/services/theme.service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';

@Component({
  selector: 'app-no-email-verified',
  templateUrl: './no-email-verified.component.html',
  styleUrls: ['./no-email-verified.component.scss']
})
export class NoEmailVerifiedComponent implements OnInit, OnDestroy {

  public theme$: Observable<string | null>;
  public isDisabledReSendEmail = false; // Estado del botón
  private redirectURL: string;
  public timerToReSendEmail: BehaviorSubject<number> = new BehaviorSubject<number>(0); // Temporizador en segundos (5 minutos)
  private intervalSendEmailId: any;
  private intervalReloadId: any;
  private timerKey = 'timerToReSendEmail';
 
  constructor(
    private authService: AuthService,
    private themeService: ThemeService,
    private router: Router,
    private sessionStorageService: SessionStorageService
  ){
    this.theme$ = this.themeService.getTheme()
    this.reloadUser();
    this.redirectURL = '/user/info';
  }
  
  ngOnInit(): void {
    if(this.sessionStorageService.getObject(this.timerKey)){
      this.startTimer();
    }
  }

  public reSendEmailVerification(): void{
    this.startTimer();
    //this.authService.sendEmailVerification();
  }

  public reloadPage(): void{
    this.authService.reloadUser();
    this.authService.getAuthState().pipe(
      filter((user) => user !== null),
      tap(
        (user) => {
          if(user){
            console.log(user.emailVerified)
            if(user.emailVerified){
              if (this.router.url !== this.redirectURL) {
                this.router.navigate([this.redirectURL]);
              }
            }
          }
        }
      ),
    ).subscribe();
  }

  private reloadUser(): void{
    this.intervalReloadId = setInterval(() => {
      this.authService.reloadUser();
      this.authService.getAuthState().pipe(
        filter((user) => user !== null),
        tap(
          (user) => {
            if(user){
              console.log(user.emailVerified)
              if(user.emailVerified){
                if (this.router.url !== this.redirectURL) {
                  this.router.navigate([this.redirectURL]);
                }
              }
            }
          }
        ),
      ).subscribe();
    }, 4000);
  }

  startTimer(): void {
    const TIMER_DURATION = 150000; // 5 minutos en milisegundos
    const storedTime = this.sessionStorageService.getObject(this.timerKey);
  
    if (storedTime) {
      const storedTimestamp = new Date(storedTime).getTime();
      const currentTime = Date.now();
      const remainingTime = storedTimestamp + TIMER_DURATION - currentTime;
  
      if (remainingTime <= 0) {
        // Si el tiempo ya ha expirado
        console.log('El temporizador ha expirado, habilitando el botón.');
        this.sessionStorageService.removeItem(this.timerKey);
        this.isDisabledReSendEmail = false;
        clearInterval(this.intervalSendEmailId);
      } else {
        // Si aún queda tiempo en el temporizador
        this.isDisabledReSendEmail = true;
        this.timerToReSendEmail.next(Math.ceil(remainingTime / 1000)); // Convertir a segundos
        this.startCountdown();
      }
    } else {
      // Si no hay un temporizador guardado, inicializarlo
      console.log('Iniciando un nuevo temporizador.');
      this.sessionStorageService.setObject(this.timerKey, new Date());
      this.isDisabledReSendEmail = true;
      this.timerToReSendEmail.next(TIMER_DURATION / 1000); // Convertir a segundos
      this.startCountdown();
    }
  }
  
  private startCountdown(): void {
    // Inicia el intervalo para el temporizador
    this.intervalSendEmailId = setInterval(() => {
      this.timerToReSendEmail.next(this.timerToReSendEmail.value - 1);
  
      if (this.timerToReSendEmail.value <= 0) {
        console.log('El temporizador ha finalizado.');
        clearInterval(this.intervalSendEmailId);
        this.isDisabledReSendEmail = false;
        this.sessionStorageService.removeItem('timerToReSendEmail');
      }
    }, 1000);
  }
  

  ngOnDestroy(): void {
    if (this.intervalSendEmailId) {
      clearInterval(this.intervalSendEmailId);
    }
    if (this.intervalReloadId) {
      clearInterval(this.intervalReloadId);
    }
  }

}
