import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { catchError, defaultIfEmpty, from, map, Observable, of, switchMap, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  private redirectURL: string;

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {
    this.redirectURL = '/auth/login';
  }

  public canActivate(): Observable<boolean> {
    return this.authService.getAuthState().pipe(
      take(1), // Toma el primer valor emitido
      defaultIfEmpty(null), // Si no se emiten valores, devuelve null
      switchMap((user) => {
        if (!user) {
          if (this.router.url !== this.redirectURL) {
            this.router.navigate([this.redirectURL]);
          }
          return of(false); // Devuelve false si no hay usuario
        }
        return from(user.getIdToken()).pipe(
          map((token) => {
            const isExpired = this.authService.isTokenExpired(token);
            if (isExpired) {
              this.authService.logout();
              if (this.router.url !== this.redirectURL) {
                this.router.navigate([this.redirectURL]);
              }
              return false; // Bloquea el acceso
            }
            return true; // Permite el acceso
          }),
          catchError((error) => {
            console.error('Error al procesar el token:', error);
            this.router.navigate(['/error/' + error.toString()]);
            return of(false); // Bloquea el acceso en caso de error
          })
        );
      })
    );
  }

}